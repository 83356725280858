import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import congesService from '@data/congesService';

import { classNames } from '@utility/Utils';

export default function (setColumns) {
  const [groupColumn, setGroupColumn] = useState([]);
  const { t } = useTranslation();

  const getLeaveTypes = async () => {
    try {
      const leaveCounts = await congesService.post('/v1/leave-types/search', {
        filters: [
          { field: 'is_active', operator: '=', value: true },
          { field: 'needs_count', operator: '=', value: true },
        ],
        sort: [
          {
            field: 'order_appearance',
            direction: 'asc',
          },
        ],
      });

      // Group leave types by leave_code
      const groupedLeaveTypes = leaveCounts.data.data.reduce((acc, element) => {
        if (!acc[element.leave_code]) {
          acc[element.leave_code] = [];
        }
        acc[element.leave_code].push(element);
        return acc;
      }, {});

      // Create columns for each group
      Object.entries(groupedLeaveTypes).forEach(([leaveCode, leaveTypes]) => {
        const isPay = leaveTypes.some((lt) => lt.is_pay);

        if (isPay) {
          setGroupColumn((prevState) => [
            ...prevState,
            {
              groupId: `${leaveCode}-n1`,
              headerName: `${leaveTypes[0].name} N-1`,
              headerAlign: 'center',
              headerClassName:
                'mr-3 bg-lightgrey-50 text-gray-600 font-semibold text-sm border-bottom',
              children: [
                { field: `acquired-${leaveCode}-n1` },
                { field: `take-${leaveCode}-n1` },
                { field: `solde-${leaveCode}-n1` },
              ],
              sortable: false,
            },
            {
              groupId: `${leaveCode}`,
              headerName: `${leaveTypes[0].name}`,
              headerAlign: 'center',
              headerClassName:
                'mr-3 bg-lightgrey-50 text-gray-600 font-semibold text-sm border-bottom',
              children: [
                { field: `acquired-${leaveCode}` },
                { field: `take-${leaveCode}` },
                { field: `solde-${leaveCode}` },
              ],
              sortable: false,
            },
          ]);
        } else {
          setGroupColumn((prevState) => [
            ...prevState,
            {
              groupId: `${leaveCode}`,
              headerName: `${leaveTypes[0].name}`,
              headerAlign: 'center',
              headerClassName:
                'mr-3 bg-lightgrey-50 text-gray-600 font-semibold text-sm border-bottom',
              children: [
                { field: `acquired-${leaveCode}` },
                { field: `take-${leaveCode}` },
                { field: `solde-${leaveCode}` },
              ],
              sortable: false,
            },
          ]);
        }

        // Update setColumns logic
        setColumns((prevState) => [
          ...prevState,
          {
            field: `acquired-${leaveCode}`,
            headerName: t('acquired'),
            width: 60,
            type: 'number',
            headerAlign: 'center',
            sortable: false,
            headerClassName:
              'title-col bg-lightgrey-50 text-xs text-lightgrey-500',
          },
          {
            field: `take-${leaveCode}`,
            headerName: t('taken'),
            width: 60,
            type: 'number',
            headerAlign: 'center',
            sortable: false,
            headerClassName:
              'title-col bg-lightgrey-50 text-xs text-lightgrey-500',
          },
          {
            field: `solde-${leaveCode}`,
            headerName: t('solde'),
            width: 60,
            type: 'number',
            headerAlign: 'center',
            sortable: false,
            headerClassName: `title-col mr-3 bg-lightgrey-50 text-xs text-lightgrey-500`,
            renderCell: (params) => {
              const solde =
                Number(params.row[`acquired-${leaveCode}`]) -
                Number(params.row[`take-${leaveCode}`]);
              const isCountersWeird =
                Math.round(solde * 100) / 100 !==
                  params.row[`solde-${leaveCode}`] ||
                params.row[`solde-${leaveCode}`] < 0;

              return (
                <div className='flex justify-center'>
                  <span
                    className={classNames(
                      isCountersWeird
                        ? 'text-primary1 font-semibold'
                        : 'text-gray-600',
                      'text-xs'
                    )}
                  >
                    {params.value}
                  </span>
                </div>
              );
            },
          },
        ]);

        if (isPay) {
          setColumns((prevState) => [
            ...prevState,
            {
              field: `acquired-${leaveCode}-n1`,
              headerName: t('acquired'),
              width: 60,
              type: 'number',
              headerAlign: 'center',
              sortable: false,
              headerClassName:
                'title-col bg-lightgrey-50 text-xs text-lightgrey-500',
            },
            {
              field: `take-${leaveCode}-n1`,
              headerName: t('taken'),
              width: 60,
              type: 'number',
              headerAlign: 'center',
              sortable: false,
              headerClassName:
                'title-col bg-lightgrey-50 text-xs text-lightgrey-500',
            },
            {
              field: `solde-${leaveCode}-n1`,
              headerName: t('solde'),
              width: 60,
              type: 'number',
              headerAlign: 'center',
              sortable: false,
              headerClassName: `title-col mr-3 bg-lightgrey-50 text-xs text-lightgrey-500`,
              renderCell: (params) => {
                const solde =
                  Number(params.row[`acquired-${leaveCode}-n1`]) -
                  Number(params.row[`take-${leaveCode}-n1`]);
                const isCountersWeird =
                  Math.round(solde * 100) / 100 !==
                    params.row[`solde-${leaveCode}-n1`] ||
                  params.row[`solde-${leaveCode}-n1`] < 0;

                return (
                  <div className='flex justify-center'>
                    <span
                      className={classNames(
                        isCountersWeird
                          ? 'text-primary1 font-semibold'
                          : 'text-gray-600',
                        'text-xs'
                      )}
                    >
                      {params.value}
                    </span>
                  </div>
                );
              },
            },
          ]);
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const addColumns = (userLeaveCounts, row) => {
    for (const dataKey in userLeaveCounts) {
      if (Object.prototype.hasOwnProperty.call(userLeaveCounts, dataKey)) {
        const element = userLeaveCounts[dataKey];;
        const everyCountAtZero = 
          element.acquired === 0 &&
          element.taken === 0 &&
          element.balance === 0;

        const notShow = (element.leave_type.name === 'Autres' && everyCountAtZero);

        row[
          `acquired-${element.leave_type.leave_code}${
            element.is_last_year ? '-n1' : ''
          }`
        ] = notShow ? '' : element.acquired ?? 0;
        row[
          `take-${element.leave_type.leave_code}${
            element.is_last_year ? '-n1' : ''
          }`
        ] = notShow ? '' : element.taken ?? 0;
        row[
          `solde-${element.leave_type.leave_code}${
            element.is_last_year ? '-n1' : ''
          }`
        ] = notShow ? '' : element.balance ?? 0;
      }
    }
  };

  useEffect(() => {
    getLeaveTypes();
  }, []);

  return {
    groupColumn,
    addColumns,
  };
}
